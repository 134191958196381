.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .image {
    &--thumbnail {
      height: 240px;
      width: 240px;
    }
  }

  figcaption {
    margin-bottom: 10px;
    padding: 4px 10px 0;
  }

  figure {
    margin: 5px;
  }
}
