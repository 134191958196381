body {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

main {
  padding: 3.2rem 6.4rem;
  margin: auto;
  max-width: 1680px;

  @include MQ(Lneg) {
    padding: 2.4rem 4rem;
  }

  @include MQ(LMneg) {
    padding: 1rem 4rem 2.4rem;
  }

  @include MQ(Mneg) {
    padding: 1rem 2rem 2.4rem;
  }

  .title {
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
    text-transform: uppercase;
    letter-spacing: 2px;

    @include MQ(Mneg) {
      font-size: 2.6rem;
      margin-bottom: 2.6rem;
    }
  }
}

.hero {
  display: flex;
  position: relative;
  width: 100%;
  height: 32rem;
  justify-content: center;
  align-items: center;
  margin: 3.2rem 0;

  &--about {
    background-image: url('/assets/schody/schody4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &--contact {
    display: block;
    height: 52rem;
    margin: 5.2rem 0 3.2rem;

    @include MQ(MAXneg) {
      height: auto;
    }

    > div {
      max-height: 100%;
    }

    img {
      display: block;
      max-height: 100%;
      object-fit: cover;
    }
  }

  &::after {
    content: '';
    background-color: $secondary;
    opacity: 0.4;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__title {
    display: none;
    position: absolute;
    z-index: 1;
    color: map-get($basic, bg);
    font-size: 8.2rem;
    text-align: center;
    animation-name: pulse;
    animation-duration: 5s;
    animation-fill-mode: both;

    @include MQ(XLneg) {
      font-size: 6vw;
    }

    @include MQ(LMneg) {
      font-size: 6.4vw;
    }

    @include MQ(Mneg) {
      font-size: 3.4rem;
    }

    &.active {
      display: block;
    }
  }
}

.about {
  margin: 7.2rem 0 3.2rem;

  @include MQ(Mneg) {
    margin: 5.2rem 0 3.2rem;
  }

  h1 {
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
    text-transform: uppercase;
    letter-spacing: 2px;

    @include MQ(Mneg) {
      font-size: 2.6rem;
      margin-bottom: 2.6rem;
    }
  }

  &__wrapper {
    p {
      font-size: 1.8rem;
      line-height: 1.4;
      letter-spacing: 1.4px;

      @include MQ(Mneg) {
        font-size: 1.6rem;
        letter-spacing: 1.2px;
      }
    }
  }
}

.contact {
  margin: 7.2rem 0 3.2rem;

  @include MQ(Mneg) {
    margin: 5.2rem 0 3.2rem;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;

    @include MQ(LMneg) {
      flex-direction: column;
    }
  }

  &__column {
    &--form {
      margin-right: 2.8rem;
      flex: 1;

      @include MQ(XXLneg) {
        margin-right: 2rem;
      }

      @include MQ(LMneg) {
        margin-right: 0;
        margin-bottom: 2rem;
        width: 100%;
      }
    }

    &--address {
      margin-left: 0.8rem;
      margin-right: -2rem;
      display: flex;
      flex-wrap: wrap;

      @include MQ(XXLneg) {
        margin-right: -1.4rem;
      }

      @include MQ(LMneg) {
        margin-right: 0;
        margin-left: -1.4rem;
      }

      > p {
        margin: 0 2rem 2rem;
        font-size: 1.8rem;
        line-height: 1.4;
        letter-spacing: 1.4px;

        @include MQ(XXLneg) {
          margin: 0 1.4rem 2rem;
        }
      }
    }
  }

  &__company-name {
    text-transform: uppercase;
  }

  h2 {
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
    text-transform: uppercase;
  }
}

.contact-form {
  min-width: 400px;

  @include MQ(LMneg) {
    min-width: auto;
    width: 100%;
  }

  &__wrapper {
    display: block;
    padding-top: 0.6rem;
  }

  &__inner {
    margin-top: 2.4rem;
    letter-spacing: 1.4px;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__input {
    width: 100%;
    border: 2px $secondary solid;
    padding: 1rem 1.4rem;
    transition: border-color 0.4s ease-out;

    &:focus,
    &:hover,
    &:active {
      border-color: $secondaryDark;
    }

    &--textarea {
      resize: vertical;
    }
  }

  &__submit {
    background-color: $secondary;
    border: 0;
    padding: 1.2rem 2.4rem;
    text-transform: uppercase;
    color: $white;
    font-size: 1.8rem;
    letter-spacing: 1.4px;
    cursor: pointer;
    transition: background-color 0.4s ease-out;

    &:hover {
      background-color: $secondaryDark;
    }

    @include MQ(Mneg) {
      font-size: 1.6rem;
      letter-spacing: 1.2px;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  20% {
    opacity: 1;
    transform: translateZ(0);
  }

  80% {
    opacity: 1;
    transform: translateZ(0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
