@import './variables';

@mixin MQ($canvas) {
  @if $canvas==Sneg {
    @media only screen and (max-width: $S) {
      @content;
    }
  } @else if $canvas==Mneg {
    @media only screen and (max-width: $M) {
      @content;
    }
  } @else if $canvas==LMneg {
    @media only screen and (max-width: $LM) {
      @content;
    }
  } @else if $canvas==Lneg {
    @media only screen and (max-width: $L) {
      @content;
    }
  } @else if $canvas==MAXneg {
    @media only screen and (max-width: $max-width) {
      @content;
    }
  } @else if $canvas==XLneg {
    @media only screen and (max-width: $XL) {
      @content;
    }
  } @else if $canvas==XXLneg {
    @media only screen and (max-width: $XXL) {
      @content;
    }
  }
}

// %box {
//   border-radius: 6px;
//   box-shadow: 0 8px 20px 0 map-get($basic, shadow);
// }
