@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,600,700&subset=latin-ext');

$basic: (
  basic: #3e3e3e,
  bg: #f6f3f7,
  shadow: #c4c2c5,
);

$primary: #333;
$secondary: #f29c1d;
$secondaryDark: #da7710;

$white: #fff;

$fontMain: 'Roboto', sans-serif;

// breakpoints
$S: 460px;
$M: 620px;
$LM: 820px;
$L: 1000px;
$XL: 1280px;
$XXL: 1520px;
//min and max page width
$min-width: 320px;
$max-width: 1400px;
