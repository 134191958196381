/* basic custom css reset file, for larger projects I use also third party resets */

* {
  margin: 0;
  padding: 0;
  outline: 0;
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
  box-sizing: border-box;
  line-height: 1.2;
  color: map-get($basic, basic);
  font-family: $fontMain;
}

html {
  font-size: 62.5%;
}

body {
  min-width: $min-width;
  background-color: map-get($basic, bg);
  font-size: 1.6rem;
}

img {
  width: 100%;
  height: auto;
}

ul li {
  list-style: none;
}

p {
  &:not(:last-of-type) {
    margin-bottom: 14px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  width: auto;
  border: none;
  padding: 10px;
}
