footer {
  padding: 3.2rem 6.4rem;
  margin-top: auto;

  @include MQ(Lneg) {
    padding: 2.4rem 4rem;
  }

  @include MQ(Mneg) {
    padding: 2.4rem 2rem;
  }
}
