.header {
  &--main {
    padding: 2.4rem 6.4rem;
    width: 100%;
    max-width: 1680px;
    margin: 0 auto;

    @include MQ(Lneg) {
      padding: 2.4rem 4rem;
    }

    @include MQ(Mneg) {
      padding: 2.4rem 2rem;
    }
  }
}

.menu {
  .burger {
    display: none;

    @include MQ(LMneg) {
      position: absolute;
      top: 22px; //64 - 20 / 2
      right: 0;
      display: flex;
      flex-direction: column;
      width: 32px;
      height: 20px;
      cursor: pointer;
      z-index: 2;
      margin: auto;

      span {
        position: absolute;
        left: 0;
        width: 32px;
        height: 2px;
        background-color: map-get($basic, basic);
        transition: all 0.3s ease-in;

        &:first-of-type {
          top: 0;
        }

        &:nth-of-type(2) {
          top: 0;
          bottom: 0;
          margin: auto;
        }

        &:last-of-type {
          bottom: 0;
        }
      }
    }
  }

  &--main {
    display: flex;
    justify-content: flex-end;

    @include MQ(LMneg) {
      flex-direction: column;
    }
  }

  &__element {
    // TODO: add animation!!!
    position: relative;
    align-self: center;
    margin-left: 3.8rem;
    color: $primary;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.1px;

    @include MQ(LMneg) {
      display: none;
      margin-bottom: 2.4rem;
      font-size: 1.8rem;
      letter-spacing: 1.4px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:after {
      content: '';
      background-color: transparent;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      height: 3px;
      bottom: -8px;
      transition: all 0.35s ease-out;
    }

    &:hover {
      font-weight: 700;

      &:after {
        background-color: $secondary;
        width: 100%;
      }
    }

    &--home {
      margin-right: auto;

      @include MQ(LMneg) {
        display: block;
        position: absolute;
        width: 230px;
        top: 0;
        left: 0;
      }
    }

    &--opening {
      @include MQ(LMneg) {
        margin-top: 120px;
      }
    }
  }

  &__wrapper {
    position: relative;
    min-height: 60px;

    @include MQ(LMneg) {
      &.show {
        ul {
          li {
            display: block;
          }
        }

        .burger {
          span {
            transform-origin: center;
            position: absolute;

            &:first-of-type {
              transform: rotate(45deg);
              top: 10px;
            }
            &:nth-of-type(2) {
              display: none;
            }
            &:last-of-type {
              transform: rotate(-45deg);
              top: 10px;
            }
          }
        }
      }
    }
  }
}

.obicia-schodow-betonowych {
  .menu--obicia-schodow-betonowych {
    &:after {
      background-color: $secondary;
      width: 100%;
    }
  }
}

.schody-drewniane {
  .menu--schody-drewniane {
    &:after {
      background-color: $secondary;
      width: 100%;
    }
  }
}

.porecze-i-balustrady {
  .menu--porecze-i-balustrady {
    &:after {
      background-color: $secondary;
      width: 100%;
    }
  }
}
